// Header.js

import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import callIcon from '../components/icons/voice-call-icon.png'
import video from '../components/icons/vdo-call.png'
import menu from '../components/icons/3dot.svg'

const Header = ({ profilePicture, customName, lastSeen, online, updatePicture }) => {
  const formatLastSeen = () => {
    if (online) {
      return 'Online';
    } else if (lastSeen) {
      const lastSeenDate = new Date(lastSeen);
      const currentDate = new Date();

      const isSameDay = (date1, date2) =>
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate();

      if (isSameDay(lastSeenDate, currentDate)) {
        // Today
        return `Last Seen Today at ${lastSeenDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`;
      } else if (isSameDay(new Date(lastSeenDate.getTime() + 24 * 60 * 60 * 1000), currentDate)) {
        // Yesterday
        return `Last Seen Yesterday at ${lastSeenDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`;
      } else {
        // Another day
        return `Last Seen on ${lastSeenDate.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}`;
      }
    } else {
      return 'Last Seen N/A';
    }
  };

  const renderStatus = () => {
    if (online) {
      return <p className="online-status">Online</p>;
    } else {
      return <p>{formatLastSeen()}</p>;
    }
  };

  const handleCallClick = () => {
    const phoneNumber = '+917767927877';
    window.location.href = `tel:${phoneNumber}`;
  };  

  return (
    <div className="header-container">
      <img src={profilePicture} alt="Profile" className="profile-picture" />
      <div className="user-info">
        <h2>{customName}</h2>
        {renderStatus()}
      </div>
      <div className="icons-container">
        <img
          src={video}
          alt="Video"
          className='vdo-icon'
        />
        <img
          src={callIcon}
          alt="Call"
          className="calling-icon"
          onClick={() => handleCallClick()}
        />
        <Link to="/drive">
          <img src={menu} alt="Menu" className="menu-icon"/>
        </Link>
      </div>
    </div>
  );
};

export default Header;
