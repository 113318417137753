import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './ChatPage.css';
import ReceivedMessages from './recMsg';
import SendMessage from './sendMsg';
import serverUrl from '../config';
// import galleryico from '../components/icons/gallery.png';
import Modal from 'react-modal';
import Header from './header';


function ChatPage() {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [messagesToDisplay, setMessagesToDisplay] = useState(50); // Number of messages to display
  const { loggedInUser } = useParams();
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  // const [targetUsername, setTargetUsername] = useState(null);

// eslint-disable-next-line
const [lastSeen, setLastSeen] = useState(null);
  const [profileInfo, setProfileInfo] = useState({
    profilePicture: null,
    customName: '',
  });
  
  const fetchProfileInfo = React.useCallback(async (targetUsername) => {
    try {
      const response = await fetch(`${serverUrl}/api/get-user-status/${targetUsername}/`);
      if (response.ok) {
        const data = await response.json();
        setProfileInfo({
          profilePicture: `${serverUrl}${data.profile_picture_url}`,
          customName: data.display_name,
          lastSeen: data.last_seen,
          online: data.online,
        });
      } else if (response.status === 404) {
        console.error('User not found:', response.status);
        setProfileInfo({
          profilePicture: null,
          customName: 'Unknown User',
          lastSeen: null,
        });
      } else {
        console.error('Error fetching profile information:', response.status);
      }
    } catch (error) {
      console.error('Error fetching profile information:', error);
    }
  }, [setProfileInfo]);

  useEffect(() => {
    fetchProfileInfo(loggedInUser === 'Pratik' ? 'Jaanvi' : 'Pratik');
  }, [fetchProfileInfo, loggedInUser]);
  

  const openImageModal = (image) => {
    if (image !== null) {
      setSelectedImage(image);
      setModalIsOpen(true);
    }
  };
  

  const closeImageModal = () => {
    setSelectedImage(null);
    setModalIsOpen(false);
  };

  // console.log('Selected Image:', selectedImage); 


  const messagesContainerRef = useRef(null);

  const fetchMessages = React.useCallback(async () => {
    try {
      if (loggedInUser) {
        let sender, receiver;
  
        if (loggedInUser === 'Pratik') {
          sender = 'Pratik';
          receiver = 'Jaanvi';
        } else if (loggedInUser === 'Jaanvi') {
          sender = 'Jaanvi';
          receiver = 'Pratik';
        } else {
          navigate('/login');
          return;
        }
  
        const response = await fetch(`${serverUrl}/api/get-messages/?sender=${sender}&receiver=${receiver}`);
        if (response.ok) {
          const data = await response.json();
  
          // Check if messages are received
          if (data.messages && data.messages.length > 0) {
            // User is online
            setLastSeen(null); // Reset lastSeen when messages are received
            // Update user status to online
            // Implement the logic to update the user status to online
            // (You can use the same logic you have for updating the user status in other parts of your code)
          } else {
            // No messages received, update lastSeen based on the time of the API call
            setLastSeen(new Date().toISOString());
            // Implement the logic to update the user status to lastSeen
            // (You can use the same logic you have for updating the user status in other parts of your code)
          }
  
          // Update messages
          setMessages(data.messages);
          localStorage.setItem('chatMessages', JSON.stringify(data.messages));
        } else {
          console.error('Error in response:', response.status);
        }
      }
    } catch (error) {
      console.error('Error while fetching messages:', error);
    }
  }, [loggedInUser, navigate]);
  

  const loadPreviousMessages = () => {
    setMessagesToDisplay(messagesToDisplay + 100);
  };

  // useEffect(() => {
  //   fetchProfileInfo();
  // }, [fetchProfileInfo, loggedInUser]);
  useEffect(() => {
    const storedMessages = JSON.parse(localStorage.getItem('chatMessages')) || [];
    setMessages(storedMessages);

    const messagesContainer = messagesContainerRef.current;

    const interval = setInterval(() => {
      if (loggedInUser) {
        fetchMessages().then((data) => {
          if (messagesContainer && data) { // Add a check for the existence of data
            setMessages(() => {
              const updatedMessages = JSON.parse(localStorage.getItem('chatMessages')) || [];
              return [...updatedMessages];
            });

            // Set lastSeen when available
            if (data.last_seen) {
              setLastSeen(data.last_seen);
              console.log('Last Seen:', data.last_seen);
            }
          }
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [fetchMessages, loggedInUser, navigate]); 

  useEffect(() => {
    const messagesContainer = messagesContainerRef.current;

    if (messagesContainer) {
      const shouldScrollToBottom =
        messagesContainer.scrollTop + messagesContainer.clientHeight === messagesContainer.scrollHeight;

      if (shouldScrollToBottom) {
        messagesContainer.scrollTop = messagesContainer.scrollHeight;
      }
    }
  }, [messages]);

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
  
    return `${formattedHours}:${minutes} ${ampm}`;
  };
  
  // console.log("Last Seen in ChatPage:", lastSeen);

  return (
    <div className="chat-page">
      <Header
        profilePicture={profileInfo.profilePicture}
        customName={profileInfo.customName}
        lastSeen={profileInfo.lastSeen}
        online={profileInfo.online}
        updatePicture={fetchProfileInfo}
      />

      <div className="chat-container" ref={messagesContainerRef}>  
        {messages.length < messagesToDisplay && (
          <button onClick={loadPreviousMessages}>Load Previous Messages</button>
        )}
        
        {/* Display last seen information */}
        {/* {lastSeen && <p>Last Seen: {lastSeen}</p>} */}
                
        <ReceivedMessages
          messages={messages.slice(-messagesToDisplay)}
          loggedInUser={loggedInUser}
          formatTime={formatTime}
          setMessages={setMessages}
          openImageModal={openImageModal}
        />
              {/* Image Modal */}
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeImageModal}
                className="react-modal-content"
                overlayClassName="react-modal-overlay"
              >
                <div className="image-modal-container">
                  <button className="modal-close-btn" onClick={closeImageModal}>
                    Close
                  </button>
                  {selectedImage !== null && (
                    <img
                      src={`${serverUrl}${selectedImage}`}
                      alt="Full Size"
                      style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                    />
                  )}
                </div>
              </Modal>


        <SendMessage
          loggedInUser={loggedInUser}
          setMessages={setMessages}
          message={message}
          setMessage={setMessage}
        />
      </div>
    </div>
  );
}

export default ChatPage;
