import React, { useState, useEffect, useCallback } from 'react';
import './loginPage.css';
import { useNavigate } from 'react-router-dom';
import serverUrl from '../config';

function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Define handleLogin using useCallback to memoize the function
  const handleLogin = useCallback(async () => {
    setError('');
    try {
      const response = await fetch(`${serverUrl}/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `username=${username}&password=${password}`,
      });

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.message === 'Login successful') {
          // Save credentials to localStorage
          localStorage.setItem('username', username);
          localStorage.setItem('password', password);

          navigate(`/chat/${username}`);
        } else {
          setError('Login failed');
        }
      } else {
        const errorData = await response.json();
        setError(errorData.error);
      }
    } catch (error) {
      setError('An error occurred while logging in.');
      console.error('Login error:', error);
    }
  }, [username, password, navigate]);

  useEffect(() => {
    // Attempt auto-login on component mount
    const storedUsername = localStorage.getItem('username');
    const storedPassword = localStorage.getItem('password');

    if (storedUsername && storedPassword) {
      setUsername(storedUsername);
      setPassword(storedPassword);
      handleLogin();
    }
  }, [handleLogin]);

  return (
    <div>
      <h1>Login</h1>
      <div>
        <input className="login-inp"
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div>
        <input className="login-inp"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button className="login-bt" onClick={handleLogin}>Login</button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
}

export default LoginPage;
