// drive.js

import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import serverUrl from '../config';
import './drive.css';

const Drive = () => {
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const formatFileSize = (sizeInBytes) => {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;

    if (sizeInBytes < kilobyte) {
      return sizeInBytes + ' B';
    } else if (sizeInBytes < megabyte) {
      return (sizeInBytes / kilobyte).toFixed(2) + ' KB';
    } else {
      return (sizeInBytes / megabyte).toFixed(2) + ' MB';
    }
  };

  const fetchFiles = useCallback(async () => {
    try {
      const response = await axios.get(`${serverUrl}/api/fetch-files/`);
      const formattedFiles = response.data.files.map((file) => ({
        ...file,
        file_size_formatted: formatFileSize(file.file_size),
      }));
      setFiles(formattedFiles);
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  }, []); // No dependencies for useCallback as it doesn't depend on any props or state

  useEffect(() => {
    // Fetch files from the Django backend when the component mounts
    fetchFiles();
  }, [fetchFiles]); // Added fetchFiles to the dependency array

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setUploadProgress(0); // Reset upload progress when selecting a new file
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('file', file);
  
      await axios.post(`${serverUrl}/api/upload-file/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setUploadProgress(progress);
        },
      });
  
      // Clear the title and file state after successful upload
      setTitle('');
      setFile(null);
  
      setUploadProgress(100);
      setShowSuccessModal(true);
  
      // After successful upload, fetch the updated list of files
      fetchFiles();
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };
  
  

  const handleDownload = async (fileUrl) => {
    try {
      const response = await axios.get(`${serverUrl}${fileUrl}`, {
        responseType: 'blob', // Important for handling binary data
      });

      // Create a Blob from the response data
      const blob = new Blob([response.data]);

      // Create a link element to trigger the download
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileUrl.substring(fileUrl.lastIndexOf('/') + 1); // Extracting the file name
      link.click();
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };
  
  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <div className="drive-container">
      <h2>Personal Drive Files</h2>
      <div className="upload-form">
        <input type="file" onChange={handleFileChange} />
        <input className="drive-input" type="text" placeholder="Title" value={title} onChange={handleTitleChange} />
        <button className="upload-bt" onClick={handleUpload}>Upload</button>
        <div className="upload-progress">
          <div className="progress-bar" style={{ width: `${uploadProgress}%` }} />
        </div>
      </div>

      <div>
        <h3>Files:</h3>
        <ul className="file-list">
          {files.map((file) => (
            <li key={file.file_url}>
              <div className="file-details">
                <p>File Name: {file.title || 'Untitled'}</p>
                <p>File Size: {file.file_size_formatted}</p>
                <p>File Type: {file.file_type}</p>
                <p>Upload Time: {file.timestamp}</p>
                <button
                  className="file-download-btn"
                  onClick={() => handleDownload(file.file_url)}
                >
                  Download
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Success Modal */}
      {showSuccessModal && (
        <div className="upload-success-modal">
          <p>File successfully uploaded!</p>
          <button onClick={handleCloseSuccessModal}>Close</button>
        </div>
      )}
    </div>
  );
};

export default Drive;
