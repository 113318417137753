import React, { useState } from 'react';
import Modal from 'react-modal';
import serverUrl from '../config';
import './sendMsg.css';
import sendico from '../components/icons/send-ico.png';
import camera from '../components/icons/camera.ico';

Modal.setAppElement('#root'); // Set the root element for accessibility

const SendMessage = ({ loggedInUser, setMessages }) => {
  const [inputMessage, setInputMessage] = useState('');
  const [uploadStatus, setUploadStatus] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const sendMessage = async () => {
    if (inputMessage.trim()) {
      const receiver = loggedInUser === 'Pratik' ? 'Jaanvi' : 'Pratik';

      const formData = new FormData();
      formData.append('sender', loggedInUser);
      formData.append('receiver', receiver);
      formData.append('content', inputMessage);
      formData.append('seen', false);

      try {
        const response = await fetch(`${serverUrl}/api/send-message/`, {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          console.error('Error in response:', response.status);
        } else {
          setInputMessage('');
          // Fetch messages again to update chat display after sending the message
          // Implement message fetching logic here or through a callback from the parent component
          // to update the chat with the newly sent message.
        }
      } catch (error) {
        console.error('Error while sending the message:', error);
      }
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
  
    if (file) {
      try {
        setLoading(true);
        setModalIsOpen(true); // Open the modal
  
        const formData = new FormData();
        formData.append('sender', loggedInUser);
        formData.append('receiver', loggedInUser === 'Pratik' ? 'Jaanvi' : 'Pratik');
  
        // Check if the uploaded file is an image or a video
        const isImage = file.type.startsWith('image');
  
        if (isImage) {
          formData.append('image', file);
        } else {
          formData.append('video', file);
        }
  
        const response = await fetch(`${serverUrl}/api/upload-image/`, {
          method: 'POST',
          body: formData,
        });
  
        if (response.ok) {
          const data = await response.json();
          // Handle the uploaded image/video data as needed
          console.log('File uploaded successfully:', data.file_name);
          setUploadStatus('Uploaded successfully!');
        } else {
          console.error('Error uploading file:', response.status);
          setUploadStatus('Error uploading file.');
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        setUploadStatus('Error uploading file.');
      } finally {
        setLoading(false);
      }
    }
  };
  
  

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const isInputEmpty = inputMessage.trim() === '';

  return (
    <div className="message-input">
      <textarea
        placeholder="Message"
        value={inputMessage}
        onChange={(e) => setInputMessage(e.target.value)}
      />
      {isInputEmpty ? (
        <label htmlFor="imageUpload" className="message-input-button">
          <>
            <img src={camera} alt="Upload" />
            <input
              type="file"
              id="imageUpload"
              accept="image/*,video/*"
              onChange={handleImageUpload}
              style={{ display: 'none' }}
            />
          </>
        </label>
      ) : (
        <img
          src={sendico}
          alt="Send"
          className="message-input-button"
          onClick={sendMessage}
          style={{ cursor: loading ? 'not-allowed' : 'pointer' }}
        />
      )}

      {/* Modal for displaying upload status */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Upload Status"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          },
          content: {
            width: '300px',
            margin: 'auto',
            backgroundColor: '#fff',
            padding: '20px',
            borderRadius: '8px',
          },
        }}
      >
        <h2>Upload Status</h2>
        <p>{uploadStatus}</p>
        <button onClick={closeModal}>Close</button>
      </Modal>
    </div>
  );
};

export default SendMessage;
