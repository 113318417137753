import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './animehome.css';

// import AttackOnTitan from './anime-poster/Attack On Titan.jpeg';
import DeathNote from './anime-poster/Death Note.jpeg';
import DragonBall from './anime-poster/Dragon Ball.jpeg';
import MyDressUpDarling from './anime-poster/My Dress Up Darling.jpeg';
import OnePiece from './anime-poster/One Piece.jpeg';
import ChainsawMan from './anime-poster/Chainsaw man.png';
import DemonSlayer from './anime-poster/Demon Slayer.jpeg';
import JujutsuKaisen from './anime-poster/Jujutsu Kaisen.jpeg';
import Naruto from './anime-poster/Naruto.jpeg';
import RentAGirlfriend from './anime-poster/Rent a Girlfriend.jpeg';
import WeatheringWithYur from './anime-poster/Wathering With You.webp'
import YourName from './anime-poster/Your Name.jpeg'
import Suzume from './anime-poster/Suzume.jpeg'

const Homepage = () => {
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    if (username.toLowerCase() === 'bestie') {
      navigate('/login');
    } else {
      alert('Invalid search');
    }
  };

  
  const movies = [
    { title: 'Weathering With You', imageUrl: WeatheringWithYur },
    { title: 'Your Name', imageUrl: YourName },
    { title: 'Suzume', imageUrl: Suzume },
    // { title: 'Attack On Titan', imageUrl: AttackOnTitan },
    { title: 'Death Note', imageUrl: DeathNote },
    { title: 'Dragon Ball', imageUrl: DragonBall },
    { title: 'My Dress Up Darling', imageUrl: MyDressUpDarling },
    { title: 'One Piece', imageUrl: OnePiece },
    { title: 'Chainsaw Man', imageUrl: ChainsawMan },
    { title: 'Demon Slayer', imageUrl: DemonSlayer },
    { title: 'Jujutsu Kaisen', imageUrl: JujutsuKaisen },
    { title: 'Naruto', imageUrl: Naruto },
    { title: 'Rent a Girlfriend', imageUrl: RentAGirlfriend },
  ];

  return (
    <div className="homepage">
        {/* {errorMessage && <p>{errorMessage}</p>} */}
      <header>
        <div className="logo">
          <h1>Your Anime Series</h1>
        </div>
      <div className="notifications">
        <div className="notice">
        </div>
        <div className="notification">
          <p>You have 2 new notifications.</p>
        </div>
      </div>
        <div className="tagline">
          {/* <p>Discover your favorite anime series.</p> */}
        </div>
      </header>

      <div className="search-bar">
        <input className='home-search'
          type="text"
          placeholder="Search for anime..."
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <button onClick={handleLogin}>Search</button>
      </div>

      <div className="movie-grid">
        {movies.map((movie, index) => (
          <div key={index} className="movie-item">
            <img src={movie.imageUrl} alt={movie.title} />
            <p>{movie.title}</p>
          </div>
        ))}
      </div>

      {/* Pagination */}
      <div className="pagination">
        <span>Page 1</span>
      </div>

      <footer>
        <p>&copy; 2023 Your Anime Series</p>
      </footer>

      
    </div>
  );
};

export default Homepage;
