import React, { useEffect, useRef } from 'react';
import serverUrl from '../config';

const ReceivedMessages = ({ messages, loggedInUser, formatTime, setMessages, openImageModal }) => {
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      // messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <div className="messages-wrapper">
      <div className="messages">
        {messages.map((msg) => (
          <div
            key={msg.id}
            className={`message ${msg.sender === loggedInUser ? 'sent' : 'received'}`}
          >
            <div className="message-content">
              <div className="content-and-timestamp">
                {(msg.content || msg.image || msg.video) && (
                  <div className="content">
                    {msg.content && <div>{msg.content}</div>}
                    {msg.image && (
                      <div className="image-message" onClick={() => openImageModal(msg.image)}>
                        <img
                          src={`${serverUrl}${msg.image}`}
                          alt="Sent"
                          style={{ maxWidth: '200px' }}
                        />
                      </div>
                    )}
                    {msg.video && (
                      <div className="video-message">
                        <video controls style={{ maxWidth: '200px' }}>
                          <source src={`${serverUrl}${msg.video}`} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    )}
                  </div>
                )}
                <div className="timestamp">{formatTime(msg.timestamp)}</div>
              </div>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
};

export default ReceivedMessages;
