import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ChatPage from './components/ChatPage';
import Homepage from './components/animehome';
import LoginPage from './components/loginPage';
import Drive from './components/drive';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/chat" element={<ChatPage />} />
        <Route path="/drive" element={<Drive />} />
        <Route path="/chat/:loggedInUser" element={<ChatPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
